<template>
    <div>
        <h1>Página não encontrada! 404</h1>
    </div>


</template>

<script>

    export default {
        name: "NotFound"
    }
</script>

<style lang="scss" scoped>

div{
    height: 100vh;
    @include flex-center(row);
    color: $display-white;

    h1 {
        @include headline-1($display-white);
        background-color: rgba(0, 0, 0, 0.651);
        padding: 16px 32px; 
        border-radius: $border-radius-1;
    }
}

</style>

